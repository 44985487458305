<template>
  <div class="page flex-col">
    <div class="block_3 flex-col">
      <div class="section_6 flex-row">
        <span class="text_33">9:41</span>
        <img
          class="label_10"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngab1f668ef389f8d1eabe926edde5b79edcd3b25e5eef9ee6f587487a865052d1"
        />
        <img
          class="label_11"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga8787b58a7be32d2cf2201bba25050f496df02953514fa23ffe6e318b971df2a"
        />
        <img
          class="image_34"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd5f65e1b1646778e8abcd7b7f8550f29f733694823847b68f9e9aa344d7566ac"
        />
      </div>
      <div class="nav-bar_4 flex-row">
        <img
          class="icon_6"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0ad4592d32194580ac6febae84c3f7f2_mergeImage.png"
        />
        <span class="text_34">IPmotion</span>
        <img
          class="image_35"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3bf7b6a210f142e496d60456e1b12fda_mergeImage.png"
        />
      </div>
    </div>
    <div class="box_3 flex-col">
      <div class="block_4 flex-row">
        <div class="image-wrapper_8 flex-col justify-between">
          <img
            class="thumbnail_4"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngec9b062bda83ee69ebfaa3bae74498cda35612ab6d315b6c5c10558050c329ed"
          />
          <img
            class="label_12"
            referrerpolicy="no-referrer"
            src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/869d8b331df94322a3736ec89a301c3a_mergeImage.png"
          />
        </div>
        <img
          class="image_36"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e9a560a81e2b445bab9fd7fe80b90594_mergeImage.png"
        />
        <img
          class="image_37"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f97803c711f1457a9109f80dde09cd1f_mergeImage.png"
        />
        <img
          class="image_38"
          referrerpolicy="no-referrer"
          src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a350b27f584e42fa8eb6010c423f936e_mergeImage.png"
        />
        <img
          class="icon_2"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2dc6ef30474ae45c772066844a621d16a48cd3c5ef2d0c5f71858e9eb26ffea5"
        />
      </div>
      <img
        class="image_39"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5cb87d4662f5aa37a6c892b66234e2d1ad94e722ca7958ffa41d0f465d6737ed"
      />
      <div class="text-group_42 flex-col justify-between">
        <span class="text_3">AI智能化产品平台</span>
        <span class="paragraph_1"
          >IPmotion和东浩兰生深度合作，通过AI技术和模块化产品<br />（包括生成式AI技术、大模型和多模态工具、SaaS应用等），实现对各应用产品的模块平台化和AI智能化迭代。</span
        >
      </div>
      <button class="button_1 flex-col" @click="onClick_1">
        <div class="image-text_14 flex-row justify-between">
          <span class="text-group_2">了解更多</span>
          <img
            class="icon_3"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng684cc5b949532c34c344f0a0d99d4d7cd845773c2069abbd06d1c02c90b73490"
          />
        </div>
      </button>
    </div>
    <div class="box_4 flex-row">
      <div class="image-text_15 flex-row justify-between">
        <span class="text-group_3">会务系统</span>
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc1082c74f4d007e1124e3b32fb8da2671f95c4513e4511d91c85bbbaae2aaa93"
        />
      </div>
    </div>
    <div class="box_5 flex-col justify-end">
      <div class="image-text_16 flex-row justify-between">
        <span class="text-group_4">数字分析</span>
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng21a44fe125e6780e17d8b64e1aaba3e55f9cbc9526c45026b24970c1ded7e6ee"
        />
      </div>
      <div class="text-wrapper_1 flex-col">
        <span class="text_4"
          >利用生成式AI+模块化产品，实现对会务管理系统的模块选择、快速搭建和智能管理，复用到各大展会，用于提高大会效率和质量。</span
        >
      </div>
    </div>
    <div class="block_5 flex-col">
      <div class="list_4 flex-col">
        <div
          class="image-text_4 flex-row"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData0"
          :key="index"
        >
          <span class="text-group_5" v-html="item.lanhutext0"></span>
          <img
            class="image_9"
            referrerpolicy="no-referrer"
            :src="item.lanhuimage0"
          />
        </div>
      </div>
      <div class="box_7 flex-row">
        <div class="image-text_17 flex-row justify-between">
          <span class="text-group_6">AIGC</span>
          <img
            class="image_10"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc1082c74f4d007e1124e3b32fb8da2671f95c4513e4511d91c85bbbaae2aaa93"
          />
        </div>
      </div>
    </div>
    <div class="block_6 flex-col">
      <span class="text_35">发展历程</span>
      <div class="group_9 flex-row">
        <div class="section_7 flex-col">
          <div class="text-group_43 flex-col justify-between">
            <span class="text_36">云展平台</span>
            <span class="text_37">AI家园&amp;“造”字云展平台</span>
          </div>
          <div class="text-group_44 flex-col justify-between">
            <span class="text_38">云展平台</span>
            <span class="paragraph_5">云平台2.0<br />工博会在线</span>
          </div>
          <div class="text-group_45 flex-col justify-between">
            <span class="text_39">元宇宙平台</span>
            <span class="text_40">元境星球</span>
          </div>
          <div class="text-group_46 flex-col justify-between">
            <span class="text_41">智慧云展平台</span>
            <span class="paragraph_6"
              >智慧云平台（大数据推荐）<br />元宇宙平台</span
            >
          </div>
          <div class="text-group_47 flex-col justify-between">
            <span class="text_42">AIGC应用平台</span>
            <span class="text_43">AIGC应用产品</span>
          </div>
        </div>
        <div class="image-wrapper_9 flex-col justify-between">
          <img
            class="image_40"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng75646f92278d96d6bed5c45ea5631964bf3b17f8e4c3eaf6a4c3ca6525022c91"
          />
          <img
            class="image_41"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1b37763f7aad21b9724b6ccffe24e185a02b28f419c3eb27deb96b228ae370a0"
          />
        </div>
        <div class="image-wrapper_10 flex-col">
          <img
            class="image_42"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7c667a192e4b35acea2bed12724acd0a8d20f9aa31e1dfec1217413e95eab483"
          />
          <img
            class="image_43"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8c700f69970130b476a2eda15537ed447a79ddd858b1d865511e3824726fec85"
          />
          <img
            class="image_44"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng78df277a3d3c7764b84faa83344605e46a82071fba84ebf60a2d584ca4e91aa0"
          />
          <img
            class="image_45"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43a2fc8b03c93061e7650e9d970afe19cb8469cfc8cb4e31ddbff205457e4d12"
          />
          <img
            class="image_46"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng765fc5ad4e8a39344bef84c91f0d92fb2c053bcbd22b0120c674291db87c5423"
          />
          <img
            class="image_47"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng336a5fde38215a2ac7f37f51526533fd550f2ce59fb15903239174e05e5640dc"
          />
          <img
            class="image_48"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8e898bc7178998dfe9d5d312283e96ae0160400d2a872fd28547bd4dd9723161"
          />
          <img
            class="image_49"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfeb64ebeeea2ec9812da1c3a854fc733246f0811d27a9f212114db3402141331"
          />
          <img
            class="image_50"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcdb43d6a98f3bf35d0d8b2d74b59f57d7a266f9685dcef8258f76acc5dcf4b00"
          />
        </div>
        <div class="section_8 flex-col">
          <div class="text-group_48 flex-col justify-between">
            <span class="text_44">会务管理系统</span>
            <span class="text_45">会务管理系统1.0</span>
          </div>
          <div class="text-group_49 flex-col justify-between">
            <span class="text_46">大会网站</span>
            <span class="text_47">WAIC网站</span>
          </div>
          <div class="text-group_50 flex-col justify-between">
            <span class="text_48">会务管理系统</span>
            <span class="text_49">招商招展&amp;购票注册系统</span>
          </div>
          <div class="text-group_51 flex-col justify-between">
            <span class="text_50">大会网站</span>
            <span class="text_51">WAIC网站</span>
          </div>
          <div class="text-group_52 flex-col justify-between">
            <span class="text_52">数字会务管理系统</span>
            <span class="text_53">数字会务系统（AIGC+SaaS）</span>
          </div>
          <div class="text-group_53 flex-col justify-between">
            <span class="text_54">大数据分析平台</span>
            <span class="paragraph_7"
              >大数据分析平台<br />（大数据管理、智能分析）</span
            >
          </div>
        </div>
      </div>
      <span class="text_55">2023</span>
      <div class="text-wrapper_5 flex-col">
        <span class="text_56">2020</span>
      </div>
      <div class="text-wrapper_6 flex-col">
        <span class="text_57">2021</span>
      </div>
      <div class="text-wrapper_7 flex-col">
        <span class="text_58">2022</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/122bbd7eb3f1401b901d3abc95853218_mergeImage.png) 100% no-repeat',
          lanhutext0: '智慧云展',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc1082c74f4d007e1124e3b32fb8da2671f95c4513e4511d91c85bbbaae2aaa93',
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/70433a94ba374265b7a085999baa2038_mergeImage.png) 100% no-repeat',
          lanhutext0: '数字孪生',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc1082c74f4d007e1124e3b32fb8da2671f95c4513e4511d91c85bbbaae2aaa93',
        },
      ],
      constants: {},
    };
  },
  methods: {
    onClick_1() {
      alert(1);
    },
  },
};
</script>
<style src="../assets/css/aimcommon.css" />
<style src="../assets/css/aimindex.css" />
